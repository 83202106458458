/* eslint-disable @next/next/no-img-element */
import React, { useEffect, useRef, useState, createRef, useMemo } from 'react';

import Container from '../components/common/Container';

import toast from 'react-hot-toast';
import Loading from '../components/common/Loading';
import { useRouter } from 'next/router';
import { baseUrl } from '@/shared/baseUrl';
import dynamic from 'next/dynamic';
import ProductSlider from '../components/common/Slider/productSlider';
import SliderHeader from '../components/common/Slider/headerSlider';
import Head from 'next/head';
import FacadeProductSlider from '@/components/common/Slider/FacadeProductSlider';
const CountDown = dynamic(() => import('@/components/home/CountDown'), { ssr: false });
const Products = dynamic(() => import('@/components/home/Products'), { ssr: false });

const Youtube = dynamic(() => import('@/components/common/Youtube'), { ssr: false });

const ProductRatingSlider = dynamic(() => import('@/components/common/Slider/productRatingSlider'), {
    ssr: false,
    loading: () => <FacadeProductSlider />,
});

const Home = ({ data }) => {
    const router = useRouter();
    const navigate = router.push;

    const [flashProducts, setFlashProducts] = useState([]);

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        if (query.get('paysuccess')) {
            localStorage.removeItem('cartItems');
            setTimeout(() => {
                toast.success('Votre commande a bien été passée!');
                navigate('/');
            }, 3000);
        } else if (query.get('paycanceled')) {
            navigate('/');
            setTimeout(() => {
                toast.error("Le paiement de votre commande a été annulé. N'hésitez pas à renouveler l'opération.");
                navigate('/');
            }, 3000);
        }
    }, []);

    // const fetchSliderProducts = async () => {
    //     try {
    //         const response = await fetch(baseUrl + `/api/product/random`, {
    //             method: 'POST',
    //         });
    //         let res = await response.json();
    //         setSliderProducts(res.randomData);

    //         // dispatch({ type: ActionTypes.SET_RANDOM, payload: res.randomData });
    //     } catch (error) {
    //         console.log('Error fetching category data:', error);
    //     }
    // };

    const fetchFlashProducts = async () => {
        try {
            const response = await fetch(baseUrl + '/api/home/products', {
                method: 'POST',
            });
            const flashData = await response.json();
            setFlashProducts(flashData);
        } catch (err) {
            console.log('Failed to fetch flash products', err);
        }
    };

    const isMobile = typeof window !== 'undefined' ? window.innerWidth < 600 : false;

    useEffect(() => {
        // fetchSliderProducts().catch((res) => {});
        fetchFlashProducts().catch((err) => {
            console.log(err);
        });
    }, []);

    return (
        <>
            <Head>
                <title>livraison partout a dakar</title>
                <link rel="preload" as="style" href="../styles/globals.css" />
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                <link rel="manifest" href="/site.webmanifest" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="theme-color" content="#000000" />
                <meta
                    name="description"
                    content="VOTRE COMPTE BAWANE ; Coffre fort 125KG blinder avec code et double clé. 385 000 CFA ; Tapis Roulant LED motorisé 4.5HP AC moteur Q6 usage domestique et ..."
                />
                <link rel="preconnect" href="https://d259iyqqedb8x2.cloudfront.net" />
            </Head>
            {data?.home ? (
                <div className="mb-16 overflow-hidden">
                    <div>
                        {' '}
                        <SliderHeader images={data.home.bannerimages} />{' '}
                    </div>

                    <Container className="relative">
                        <div className="my-7 md:text-2xl text-xl">
                            <div className="font-bold">
                                Démo réelle
                                {/* <span style={{ color: "#FF6600", fontWeight: "300" }}>
                VOIR PLUS
              </span> */}
                            </div>
                            {/* <img
                        rel="preload"
                        src={cheveron.src}
                        alt=""
                        className="absolute w-10 mt-[250px] py-4 right-[-10px] z-0"
                    /> */}
                        </div>
                        <div className="min-h-[500px] min-w-full">
                            {/* {sliderProducts.length ? (
                                <ProductSlider products={sliderProducts} />
                            ) : (
                                <FacadeProductSlider />
                            )} */}
                            {data.sliderProducts.length ? (
                                <ProductSlider isProductPriority={false} products={data.sliderProducts} />
                            ) : (
                                <FacadeProductSlider />
                            )}
                        </div>
                    </Container>

                    <CountDown />

                    <Container className="relative">
                        {flashProducts.length ? <ProductRatingSlider products={flashProducts} /> : ''}

                        {/* Live Shopping guides */}
                        <div className="mt-9">
                            <Youtube
                                video={data.home?.video}
                                videoTitre={data.home?.videoTitre}
                                videoDescription={data.home?.videoDescription}
                            />
                        </div>
                    </Container>
                    <div className="pt-5 mt-8 pb-5 bg-solidGrey" style={{ overflowX: isMobile ? 'auto' : 'none' }}>
                        <Products />
                    </div>
                </div>
            ) : (
                <Loading />
            )}
        </>
    );
};

export default Home;

export async function getServerSideProps({ req, res }) {
    const baseUrl = 'https://bawane.com';
    // const baseUrl = 'http://localhost:5000';

    let homeData = {
        data: null,
        sliderProducts: [],
    };
    const fetchHomeView = async () => {
        try {
            const response = await fetch(baseUrl + '/api/home/view', {
                method: 'POST',
            });

            let data = await response.json();
            homeData.home = data.home;
            homeData.sliderProducts = data.sliderProducts;
        } catch (err) {
            console.log('Failed to fetch home view', err);
        }
    };

    await fetchHomeView();
    res.setHeader('Cache-Control', 'public, s-maxage=10, stale-while-revalidate=31557600');

    return { props: { data: homeData } };
}
