import React, { useState } from 'react';
import Slider from 'react-slick';

function LoaderCard() {
    return (
        <div class="card facade w-[300px] bg-white rounded-md">
            <div class="card__image h-[200px] rounded-bl-0 rounded-br-0 "></div>
            <div class="card__content p-[20px]">
                <h2 className="h-[30px] "></h2>
                <p className="h-[70px] w-[80%]"></p>
            </div>
        </div>
    );
}

function FacadeProductSlider() {
    const [settings, setSettings] = useState({
        dots: false,
        speed: 500,
        slidesToScroll: 1,
        slidesToShow: 4,

        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1080,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 950,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 780,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 486,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    });
    return (
        <>
            <div className="md:block  hidden ">
                <Slider {...settings}>
                    <LoaderCard />
                    <LoaderCard />
                    <LoaderCard />
                    <LoaderCard />
                    <LoaderCard />
                </Slider>
            </div>

            <div className="md:hidden  block  scrollbar-none">
                <Slider {...settings}>
                    <LoaderCard />

                    <LoaderCard />
                </Slider>
            </div>
        </>
    );
}

export default FacadeProductSlider;
