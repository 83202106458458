/* eslint-disable @next/next/no-img-element */
import React, { useEffect, useMemo } from 'react';
import { useState } from 'react';
import Slider from 'react-slick';

const cheveron = '/icons/cheveron.svg';
import Image from 'next/image';
import customLoader from '@/shared/customImageLoader';
import CustomSlider, { Slide } from '../CustomSlider';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100px',
                width: '50px',
                borderRadius: '10px 0px 0px 10px',
                background: 'white',
                marginRight: '25px',
                overflow: 'hidden',
            }}
            onClick={onClick}
        >
            <Image priority src={cheveron} objectFit="contain" fill alt="cheveron next h-7" />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100px',
                width: '50px',
                borderRadius: '0px 10px 10px 0px',
                background: 'white',
                marginLeft: '25px',
                zIndex: '1',
            }}
            onClick={onClick}
        >
            <Image
                priority
                src={cheveron}
                objectFit="contain"
                fill
                className="rotate-180 mr-6 h-7"
                alt="cheveron prev"
            />
        </div>
    );
}

const SliderHeader = ({ images }) => {
    // const [headerImages, setHeaderImages] = useState([]);
    // const [headerImagesLoaded, setHeaderImagesLoaded] = useState(false);
    // const [isMobile, setIsMobile] = useState(true);

    // const resizeHandler = () => {
    //     setIsMobile(window.innerWidth < 800);
    // };
    // useEffect(() => {
    //     setIsMobile(window.innerWidth < 800);
    //     // setHeaderImages(images);
    //     // setHeaderImagesLoaded(true);

    //     window.addEventListener('resize', resizeHandler);
    //     return () => window.removeEventListener('resize', resizeHandler);
    // }, []);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 2,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    };

    return (
        <>
            <div className="headerslider">
                {/* <Slider {...settings}> */}
                <CustomSlider>
                    {images.map((img, index) => {
                        return (
                            <>
                                <Slide className="h-full w-full ">
                                    <Image
                                        loader={customLoader}
                                        priority
                                        fetchPriority="high"
                                        decoding="sync"
                                        className="object-cover md:object-initial h-full md:min-h-[410px] w-full "
                                        fill
                                        src={img}
                                        alt={img}
                                    />
                                </Slide>

                                {/* <div key={index} className="slidemobile">
                                <Image
                                    loader={customLoader}
                                    priority={true}
                                    style={{ objectFit: 'cover' }}
                                    // className="object-cover h-full w-full"
                                    fill
                                    src={img}
                                    alt={img}
                                />
                            </div>

                            <div key={index} className={'slidelarge'}>
                                <Image loader={customLoader} priority={true} fill src={img} alt={img} />
                            </div> */}
                            </>
                        );
                    })}
                </CustomSlider>

                {/* </Slider> */}
            </div>
        </>
    );
};

export default SliderHeader;
