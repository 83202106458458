import React, { useState } from 'react';
import Slider from 'react-slick';

const cheveron = '/icons/cheveron.svg';

import Image from 'next/image';

import ProductCard from '../ProductCard';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                zIndex: '9',
                height: '100px',
                width: '50px',
            }}
            onClick={onClick}
        >
            <Image src={cheveron} objectFit="contain" fill alt="cheveron next" />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                zIndex: '9',
                height: '100px',
                width: '50px',
            }}
            onClick={onClick}
        >
            <Image src={cheveron} objectFit="contain" fill className="rotate-180 mr-6" alt="cheveron prev" />
        </div>
    );
}

const ProductSlider = ({ products }) => {
    const [settings, setSettings] = useState({
        dots: false,
        speed: 500,

        slidesToShow: products?.length > 4 ? 4 : products.length,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1080,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 950,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 780,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 486,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    });

    // const isMobile = typeof window !== 'undefined' ? window.innerWidth < 768 : false;
    return (
        <>
            <div>
                <Slider {...settings}>
                    {products?.map((product, index) => (
                        <div
                            key={index}
                            className="px-1  flex items-center justify-center md:max-w-[320px] overflow-hidden min-h-[500px]"
                        >
                            <ProductCard isPriority={true} index={index} product={product} />
                        </div>
                    ))}
                </Slider>
            </div>

            {/*   Mobile view  */}
            {/* <div className="md:hidden flex items-center gap-4 overflow-x-scroll scrollbar-none">
                {products.map((product, index) => (
                    <div key={index} className="min-w-[250px] max-w-[320px]">
                        <ProductCard product={product} />
                    </div>
                ))}
            </div> */}
        </>
    );
};

export default ProductSlider;
