import React, { useState, useEffect } from 'react';

export const Slide = ({ children }) => {
    return <div className="mySlides hidden h-full w-full">{children}</div>;
};

function CustomSlider({ children }) {
    const [slideIndex, setSlideIndex] = useState(1);

    useEffect(() => {
        showSlides(slideIndex);
    }, [slideIndex]);
    function plusSlides(n) {
        setSlideIndex((prevIndex) => prevIndex + n);
    }

    // useEffect(()=>{
    //     setInterval(() => {
    //         plusSlides(1);
    //     }, 5000);
    // },[])

    const showSlides = (n) => {
        try {
            let i;
            let slides = document.getElementsByClassName('mySlides');
            if (n > slides.length) {
                setSlideIndex(1);
            }
            if (n < 1) {
                setSlideIndex(slides.length);
            }
            for (i = 0; i < slides.length; i++) {
                slides[i].style.display = 'none';
            }

            slides[slideIndex - 1].style.display = 'block';
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div>
            <div class="slideshow-container   m-auto">
                {children}

                <a
                    class="prev "
                    onClick={() => {
                        plusSlides(-1);
                    }}
                >
                    &#10094;
                </a>
                <a
                    class="next"
                    onClick={() => {
                        plusSlides(1);
                    }}
                >
                    &#10095;
                </a>
            </div>
            <br />
        </div>
    );
}

export default CustomSlider;
